@import '../../style/constants.scss';

.provider-page {
    margin-top: 50px;
    width: 100%;

    .tab-head {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        margin: 20px auto 40px auto;
        max-width: 800px;
        min-width: 200px;

        .tab-header {
            width: 32%;
            padding: 10px;
            background-color: $beiger;
            text-align: center;
            font-weight: 500;
            cursor: pointer;
            border-bottom: 3px $fontMain #fff;
            margin-right: 1px;

            &.selected {
                background-color: $fontMain;
                font-weight: 700;
                border-bottom: 3px solid #fff;
                color: #fff;
            }
        }
    }

    #my-form {
        width: 400px;
        margin: 20px auto auto auto;
        font-size: 16px;

        @media screen and (max-width: 500px) {
            width: 100%!important;
        }

        h3 {
            color: $fontSecond;
        }
    }

    .info-group {
        margin-top: 20px;
        display: flex;
        align-items: center;
        
        .label {
            width: 80px;
            font-weight: 500;
        }

        .value {
            padding: 10px;
            font-weight: 700;
            font-size: 20px;
        }
    }
}