@import '../../style/constants.scss';

.product-detail-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 33px 15px 20px 15px;
    margin-top: 20px;
    width: 100%;

    &:hover,
    &:active,
    &:focus {
        background: $beige;
        outline: none;
    }

    &.selected {
        box-shadow: #dc3545 0px 0px 4px inset, #dc3545 0px 0px 4px;
    }

    .basket-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $beiger;
            opacity: 0.8;
            z-index: 1;
        }

        .flex-line {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            background: $beige;
            z-index: 10;

            .flex-child {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 33%;
                height: 100%;

                &:hover,
                &:active,
                &:focus {
                    background: $fontMain;
                    color: $beige;
                }
            }
        }

        .close-line {
            position: relative;
            text-align: center;
            height: 40px;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: $beiger;
            color: $fontSecond;
            z-index: 10;

            &:hover,
            &:active,
            &:focus {
                background: $fontMain;
                color: $beige;
            }

            .basket-msg {
                color: $fontMain;
                font-size: 12px;
                line-height: 12px;
                padding: 5px;
            }
        }

        .count {
            font-size: 18px;
        }
    }

    i {
        margin: 0;
    }

    .top-line {
        position: absolute;
        top: 5px;
        right: 5px;

        .plus-icon {
            border-radius: 20px;
            padding: 5px;
            border: 1px solid $fontMain;
            width: 10px;
            height: 10px;

            &:hover,
            &:active,
            &:focus {
                color: $fontMain;
                border-color: $fontMain;
            }
        }

        .count-in-basket {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $fontMain;
            border-color: $fontMain;
            color: #fff;
            border-radius: 15px;
            font-size: 12px;
            font-weight: 600;
            width: 22px;
            height: 22px;
            line-height: 12px;
            cursor: pointer;

            &:hover,
            &:active,
            &:focus {
                background-color: lighten($fontMain, 10);
                box-shadow: 3px 3x 3px $fontMain inset;
            }
        }
    }

    .product-item {
        width: 100%;
    }

    .product-image {
        height: auto;
        width: 100%;
    }

    .product-title {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 14px;
    }

    .sale-line {
        margin-bottom: 5px;
        font-size: 10px;

        .sale {
            background-color: $fontMain;
            padding: 3px 8px;
            border-radius: 10px;
            color: $beige;
        }

        .striked {
            text-decoration: line-through;
        }
    }

    .price-line {
        border-top: solid $grey 1px;
        width: 100%;
        text-align: center;

        .price {
            color: $fontSecond;
            font-size: 18px;
        }

        .price-per {
            font-size: 10px;
        }
    }

    .warehouse-line {
        color: $black;
        font-size: 12px;
        line-height: 14px;
        padding: 5px 0;
    }
}