@import '../../style/constants.scss';

.category-item {
    position: relative;
    height: auto;
    padding-bottom: 20px;
    letter-spacing: 0.5px;

    .category-background {
        width: 100%;
        height: 200px;
        background-size: cover;
        border-radius: 3px;

        @media screen and (max-width: 767px) {
            height: 100px;
        }

        .category-overlay {
            position: relative;
            height: 100%;
            width: 100%;
            padding: 10px;
            box-sizing: border-box;

            .overlay-background {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                right: 0;
                background-color: $beige;
                opacity: 0.4;
            }

            .overlay-link {
                z-index: 10;
                color: $fontSecond;
                background-color: transparent;
                border: 3px solid $fontSecond;

                &:hover, &:active {
                    color: $fontMain;
                    border: 3px solid $fontMain;
                }
            }
        }
    }

    .category-name {
        font-size: 20px;
        padding-top: 15px;
        padding-bottom: 10px;
        font-weight: bolder;
        width: 100%;

        @media screen and (max-width: 767px) {
            font-size: 16px;
            font-weight: bold;
        }

        &:active, &:hover, &:focus {
            color: $grey;
        }
    }

    .category-link {
        font-size: 12px;
    }

    .category-description {
        font-size: 11px;
        line-height: 15px;
        font-style: italic;
    }

    .category-content {

        @media screen and (min-width: 768px) {
            display: flex;
            justify-content: space-between;
        }

        .right {
            min-width: 30%;
            text-align: left;
            margin-left: 20px;

            a {
                display: block;
                font-size: 12px;
                line-height: 26px;
                color: $fontMain;

                &:hover, &:active, &:focus {
                    color: $grey;
                }
            }
        }
    }
}