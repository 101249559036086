@import '../../style/constants.scss';

.categories-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 5px;
    background: $light;
    width: 100%;

    &.has-content {
        padding-top: 20px;
    }

    @media screen and (max-width: 599px) {
        .category-wrap {
            padding-left: 3px!important;
            padding-right: 3px!important;
        }
    }
}