@import '../../style/constants.scss';

.home-page {
    .categories-container {
        margin-top: -45px;

        @media screen and (max-width: 767px) {
            margin: 0;
        }
    }

    .top-products-container {
        border: 1px  solid $light;
    }
}