@import '../../style/constants.scss';

.checkout-header-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .checkout-header {
        box-sizing: border-box;
        max-width: 500px;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 400px) {
            padding: 10px;
        }

        .header-item {
            box-sizing: content-box!important;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border: 5px solid #fff;
            background-color: $grey;
            padding: 10px;
            border-radius: 40px;
            cursor: pointer;
            margin: 0 5px;
            line-height: 30px;

            @media screen and (max-width: 767px) {
                height: 18px;
                line-height: 18px;

                i {
                    font-size: 15px!important;
                }
            }

            @media screen and (max-width: 400px) {
                padding: 5px;
            }

            &.active {
                border-color: $beige;
                color: $beige;
                background-color: $fontMain;

                &:hover, &:active {
                    background-color: darken($fontSecond, 5);
                }
            }

            &:hover, &:active {
                background-color: $fontMain;
                color: $beige;
            }

            i {
                margin: 0;
            }
        }

        .header-text {
            font-size: 20px;
            margin-left: 10px;
            cursor: pointer;

            @media screen and (max-width: 767px) {
                display: none;
            }

            &.active {
                a {
                    color: $beige;
                }
            }
        }

        .header-arrow {
            font-size: 24px;
            margin: 0 15px;

            @media screen and (max-width: 767px) {
                margin: 0;

                i {
                    font-size: 30px!important;
                }
            }
        }
    }
}

.header-line {
    width: 100%;
    display: flex;
    justify-content: center;

    .line {
        width: 33%;
        height: 5px;
        background-color: $beige;

        &.active {
            background-color: $fontMain;
        }

        @media screen and (max-width: 767px) {
            width: 45%;
        }
    }
}