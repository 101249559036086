@import '../../style/constants.scss';

.sidebar-categories {
    position: fixed;
    left: 0;
    top: 0px;
    height: 100%;
    z-index: 10;
    width: 100%;
    overflow-y: scroll;

    .black-layer {
        position: fixed;
        top : 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: $beiger;
        opacity: 0.8;
        z-index: 2;
    }

    .sidebar-container {
        width: 400px;
        height: 100%;
        position: absolute;
        top: 0;
        left:0;
        z-index: 11;
        background: #fff;

        @media screen and (max-width: 400px){
            width: 100%;
        }

        .close-section {
            display: flex;
            align-items: center;
            background: $beige;
            color: $fontSecond;
            padding-left: 20px;
            padding-right: 20px;
            height: 60px;
            cursor: pointer;
            border-bottom: 1px solid $grey;

            .close {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;
            }

            .icon-cross {
                font-size: 12px;
                margin-right: 15px;
            }
        }

        .sidebar-content {
            background: #fff;
            border-right: 1px solid $grey;
            padding-bottom: 70px;

            @media screen and (max-width: 767px) {
                width: 100%;
                border-right: none;
            }

            .logo-section {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 80px;

                .logo {
                    padding: 10px 15px;

                    .logo-text {
                        color: white;
                        letter-spacing: 2px;
    
                        .line-1 {
                            font-size: 38px;
                            font-weight: bolder;
                            letter-spacing: 3px;
                            color: $logoRed;
                        }
    
                        .line-2 {
                            font-size: 12px;
                            text-transform: uppercase;
                            color: $fontSecond;
                            padding-bottom: 5px;
                        }
                    }
                }
            }

            .sidebar-category-items {

                .sidebar-category-item {
                    cursor: pointer;

                    &:first-of-type {
                        border-top: 1px solid $grey;
                    }

                    .category-name {
                        padding: 20px;
                        font-size: 16px;
                        font-weight: bold;
                        border-bottom: 1px solid $grey;
                        color: $fontSecond;

                        &:hover, &:active, &:focus {
                            background: $beige;
                        }
                    }

                    .category-link {
                        font-size: 11px;
                        margin-bottom: 10px;
                    }

                    .sub-category {
                        padding: 10px 20px 10px 30px;
                        font-size: 14px;
                        border-bottom: 1px solid $grey;
                        color: $fontSecond;

                        &:hover, &:active, &:focus {
                            background: $grey;
                        }
                    }
                }
            }
        }
    }
}