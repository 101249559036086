@import '../../style/constants.scss';

.basket-sidebar {
    position: fixed;
    left: 0;
    top: 0px;
    height: 100%;
    z-index: 101;
    width: 100%;
    overflow-y: auto;

    .black-layer {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: $beiger;
        opacity: 0.9;
        z-index: 11;
    }

    .sidebar-container {
        width: 500px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
        background: $beige;

        @media screen and (max-width: 500px) {
            width: 100%;
        }

        .close-section {
            display: flex;
            align-items: center;
            color: $fontSecond;
            padding-left: 20px;
            padding-right: 20px;
            height: 60px;
            border-bottom: 1px solid $grey;
            cursor: pointer;

            &:hover,
            &:active,
            &:focus {
                color: $grey;
            }

            .close {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;
            }

            .icon-cross {
                font-size: 12px;
                margin-right: 15px;
            }
        }

        .sidebar-inner {
            position: relative;
            overflow-y: scroll;
            height: calc(100% - 70px);
            width: 100%;
            margin-bottom: 20px;
        }

        .check-out-line {
            width: 100%;
            height: 50px;
            text-transform: uppercase;
            padding-left: 20px;
            padding-right: 20px;
            z-index: 101;

            .continue {
                width: 100%;
                padding: 10px;

                .continue-1 {
                    width: 50%;
                    background-color: $fontMain;
                    color: #fff;
                }

                .continue-2 {
                    width: 30%;
                    background-color: $fontMain;
                    color: #fff;
                }
            }
        }

        .sidebar-content {
            background: #fff;

            @media screen and (max-width: 767px) {
                width: 100%;
            }

            .basket-item {
                box-sizing: border-box;
                width: 100%;
                padding: 10px;

                .product-details {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;

                    .product-image {
                        user-select: none;
                        height: 60px;
                        width: 60px;
                        background-size: contain;
                        cursor: pointer;
                    }

                    .product-right {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        width: inherit;
                    }

                    .product-name {
                        padding: 0 10px;

                        .name {
                            font-weight: bold;
                            cursor: pointer;
                        }
    
                        .price-per {
                            margin-top: 10px;
                            margin-right: 10px;
                            font-size: 10px;
                            color: $fontSecond;
                        }

                        .control {
                            .remove {
                                color: $fontSecond;
                                font-size: 10px;
                                cursor: pointer;
                            }
                        }
                    }

                    .product-amount {
                        .product-count {
                            display: flex!important;
                            align-items: center;
                            justify-content: flex-start;
                            
                            input {
                                padding-left: 10px;
                                height: 48px;
                                width: 60px;
                                font-weight: 600;
                                font-size: 14px;
                                text-align: center;
                                line-height: 12px;
                                border-top: 1px solid $fontSecond;
                                border-bottom: 1px solid $fontSecond;
                                border-left: none;
                                border-right: none;
                                border-radius: 0;
                                box-sizing: border-box;
                            }
                            
                            i.caret {
                                background-color: #fff;
                                color: $black;
                                border: 1px solid $fontSecond;
                                padding: 15px;
                                margin: 0;
                                cursor: pointer;

                                &:hover, &:active {
                                    background-color: $fontSecond;
                                    color: #fff;
                                }

                                &.down {
                                    border-top-left-radius: 5px;
                                    border-bottom-left-radius: 5px;
                                }

                                &.up {
                                    border-top-right-radius: 5px;
                                    border-bottom-right-radius: 5px;
                                }
                                
                            }
                        }

                        .stock-line {
                            font-size: 12px;
                            color: $fontMain;
                            padding: 5px 0;
                        }
                    }
                }

                .product-price {
                    width: 100%;
                    text-align: right;
                }
            }
        }
    }
}