@import '../../style/constants.scss';

.payment-page {
    margin-top: 50px;

    .payment-details {
        max-width: 800px;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        color: $fontSecond;

        .transaction-details {
            .transaction-info {
                margin-top: 10px;
                color: $fontMain!important;
            }
        }

        .payment-row {
            display: flex;
            font-size: 18px;
            height: 35px;

            @media screen and (max-width: 767px) {
                height: 50px;
            }

            .payment-col-1 {
                width: 180px;
            }

            .payment-col-2 {
                font-weight: 700;
            }
        }

        .client-orders {
            font-weight: 700;
        }
    }
}