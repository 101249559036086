@import '../../style/global.scss';

.order-invoice-container {
    width: 100%;
    box-sizing: border-box;
    max-width: 800px;
    margin-left: 'auto';
    margin-right: 'auto';

    @media screen and (max-width: 800px) {
        min-width: initial;
    }

    @media screen and (max-width: 767px) {
        padding: 0 10px;
        font-size: 12px;
        width: 100%;
    }

    a {
        color: black;
        text-decoration: none;
    }

    #pdf-print {
        background-color: white;
        color: black;
        padding: 10px;
    
        .order-invoice {    

            .invoice-title {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;
                margin-top: 10px;
                margin-bottom: 20px;
                font-size: 18px;
            }

            .order-address {
                margin-top: 30px
            }

            .order-delivery {
                .order-delivery-top {
                    padding: 15px 0;
                }
            }

            table {
                border-collapse: collapse;
                width: 100%;
                padding: 15px 0;
            }

            td {
                width: 50%;
                padding: 5px;
            }

            table, th, td {
                border: 1px solid;
                font-weight: 400;
            }
        }
    }

    .order-summary {
        border-radius: 0 0 4px 4px;
        border-top: 1px solid darken($grey, 30);
        padding: 15px 0;

        .order-amount {
            font-weight: 500;
            margin-top: 10px;
        }
    }

    .order-signature {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid black;

        table {
            width: 100%;
        }

        td {
            width: 50%;
        }
    }

    .button-print {
        margin-bottom: 20px;
    }
}