@import '../../style/global.scss';

.page-client-order {
    width: 100%;
    margin: 50px auto auto auto;
    box-sizing: border-box;
    max-width: 800px;

    @media screen and (max-width: 800px) {
        min-width: initial;
    }

    @media screen and (max-width: 767px) {
        padding: 0 10px;
        font-size: 12px;
        width: 100%;
    }
    
    .client-confirmed-order {
        @media screen and (max-width: 767px) {
            width: 100%;
        }

        @media screen and (min-width: 1024px) {
            min-width: 800px;
        }
    }

    .success-header {
        color: $green;
    }

    .client-order {
        width: 100%;
        margin: 10px 0;
        background-color: #fff;
        color: $fontSecond;
        border-radius: 5px;
        padding: 10px;
        box-sizing: border-box;

        a {
            color: $fontSecond!important;
        }

        .order-top {
            display: table;
            width: 100%;
            min-height: 50px;
            box-sizing: border-box;
            padding: 15px 0;
            border-radius: 4px 4px 0 0;

            .order-col {
                display: table-cell;

                .col-top {
                    font-weight: 700;
                    margin-bottom: 10px;
                }
            }
        }

        .order-items {
            border-top: 1px solid darken($grey, 30);
            padding-bottom: 10px;

            .order-address {
                padding: 15px 0;
            }

            .order-delivery {
                .order-delivery-top {
                    padding: 15px 0;
                    font-weight: 700;
                }
            }
        }

        .order-summary {
            font-weight: 700;
            border-radius: 0 0 4px 4px;
            border-top: 1px solid darken($grey, 30);
            padding: 15px 0;
        }
    }

    .order-status {
        font-weight: 600;
        &.placed {
            color: $fontMain;
        }
        &.paid {
            color: $green;
        }
        &.confirmed {
            color: $green;
        }
    }

}