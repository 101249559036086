@import '../../../style/global.scss';

.page-order {
    width: 100%;
    margin: 30px auto;

    @media screen and (max-width: 800px) {
        min-width: initial;
    }

    @media screen and (max-width: 767px) {
        padding: 0 10px;
        font-size: 12px;
    }

    .payment-section {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid;

        @media print {
            display: none;
        }

        .payment-form {
            display: flex;
            justify-content: space-around;
            margin-top: 30px;

            @media screen and (max-width: 600px) {
                justify-content: center;
                flex-direction: column;

                .order-confirm {
                    margin-top: 20px;
                }
            }
        }

        .confirm-order {
            margin-top: 20px;
        }
    }

    .client-order {
        width: 100%;
        margin: 10px 0;
        background-color: #fff;
        color: $fontSecond;
        border-radius: 5px;

        a {
            color: $fontSecond!important;
        }

        .order-top {
            display: table;
            width: 100%;
            min-height: 50px;
            box-sizing: border-box;
            padding: 15px 0;
            border-radius: 4px 4px 0 0;

            .order-col {
                display: table-cell;

                .col-top {
                    font-weight: 700;
                    margin-bottom: 10px;
                }
            }
        }

        .order-items {
            border-top: 1px solid darken($grey, 30);
            padding-bottom: 10px;

            .order-address, .order-contact {
                padding: 10px 0;
            }

            .order-delivery {
                .order-delivery-top {
                    padding: 15px 0;
                    font-weight: 700;
                }
            }
        }

        .order-summary {
            font-weight: 700;
            border-radius: 0 0 4px 4px;
            border-top: 1px solid darken($grey, 30);
            padding: 15px 0;
        }
    }

    .order-status {
        font-weight: 600;
        &.placed {
            color: $fontMain;
        }
        &.paid {
            color: $green;
        }
        &.confirmed {
            color: $green;
        }
    }

    .print-line {
        margin-top: 20px;
        font-size: 16px;
        
        @media print {
            display: none;
        }
    }

    .receipt-container {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .receipt {
            width: 48%;
        }
    }
}