@import '../../style/constants.scss';

.category-page {
    @media screen and (min-width: 961px) {
        margin-top: 50px;
    }

    .category-page-container {

        @media only screen and (max-width: 1023px) {
            padding-left: 0!important;
            padding-right: 0!important;
        }

        .category-content {
            margin-bottom: 20px;
            margin-left: 0!important;
            margin-right: 0!important;

            .category-header {
                width: 100%;

                .breadcrumb {
                    padding: 20px 0;
                    -webkit-text-stroke: 0.2px $beiger;

                    @media screen and (max-width: 799px) {
                        padding-left: 15px;
                        padding-right: 15px;
                    }

                    span, a {
                        -webkit-text-stroke: 0.2px $beiger;
                        font-size: 14px;
                        font-weight: 700;
                        cursor: pointer;

                        @media screen and (max-width: 500px) {
                            font-size: 10px;
                        }
                    }
                }

                .category-image {
                    position: relative;
                    width: 100%;

                    img.image {
                        position: relative;
                        width: 100%;
                        max-height: 200px;

                        @media screen and (min-width: 1024px) {
                            max-height: 320px;
                        }
                    }

                    .image-content {
                        position: absolute;
                        top: 50px;
                        left: 0;
                        right: 0;
                        bottom: 50px;

                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        padding: 0 30px;

                        font-weight: bold;

                        @media screen and (max-width: 500px) {
                            top: 15px;
                        }

                        h1 {
                            font-size: 40px;
                            margin-bottom: 10px;

                            @media screen and (max-width: 500px) {
                                font-size: 25px;
                            }

                            @media screen and (max-width: 400px) {
                                font-size: 20px;
                            }
                        }
        
                        .description {
                            font-size: 16px;

                            @media screen and (max-width: 500px) {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }

        .sub-categories {
            width: 100%;
            background-color: $beige;

            .categories-container {
                width: 100%;
            }
        }

        .top-products-container {
            margin-top: 20px;
            border: 1px  solid $light;
        }
    }
}