@import '../../style/global.scss';

.company-form {
    min-width: 300px;
    margin-top: 20px;

    &.slim {
        width: 320px;
    }

    .company-controls {
        border-bottom: 1px solid;
        color: $fontSecond;

        a {
            color: $fontSecond;
        }
    }

    h2 {
        color: $fontSecond;
    }

    .form-group {
        margin-top: 20px;

        .label {
            font-weight: 700;
            margin-bottom: 10px;
        }
    }

    .info-group {
        margin-top: 20px;
        
        .label {
            color: $grey;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .value {
            color: $fontSecond;
            padding: 10px;
            font-weight: 700;
            font-size: 20px;
        }
    }

    .big-text {
        font-size: 16px;
        font-weight: 700;
    }

    .link-to {
        display: block;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 600;
    }
}