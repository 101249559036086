@import '../../style/constants.scss';

.brands-page {
    @media screen and (min-width: 961px) {
        margin-top: 50px;
    }

    @media only screen and (max-width: 1023px) {
        padding-left: 15px!important;
        padding-right: 15px!important;
    }

    .brands-content {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        
        .brand-item {
            height: unset;
            width: 100%;
            background-size: cover;
            margin: 10px 10px 10px 0;
            padding: 10px;
            cursor: pointer;

            &:hover, &:active, &:focus {
                box-shadow: 0 0 10px 0 lighten($fontMain, 10);
            }
        }
    }
}