.banner-container {
    .banner-url {
        display: flex;
        justify-content: center;

        @media screen and (max-width: 599px) {
            padding: 10px 15px;
            border-radius: 5px;;
        }

        img {
            width: 100%;
            align-self: flex-start;
            max-width: 1260px;
        }
    }

    .alice-carousel__dots {
        position: absolute;
        bottom: 50px;
        left: 50%;

        @media screen and (max-width: 599px) {
            display: none;
        }
    }
}