@import '../../style/global.scss';
@import '../../style/constants.scss';

.signup-page {
    .tab {
        width: 50%;
        padding: 10px;
        text-align: center;
        font-weight: 500;
        color: $fontSecond;
        cursor: pointer;

        &.active {
            background-color: $fontMain;
            color: #fff;
        }

        &:first-of-type {
            border-radius: 5px 0 0 5px;
        }

        &:last-of-type {
            border-radius: 0 5px 5px 0;
        }
    }

    p {
        color: $fontSecond;
    }
}