@import '../../style/constants.scss';

.search-page {
    @media screen and (min-width: 961px) {
        margin-top: 50px;
    }

    .search-page-container {
        padding-left: 5px!important;
        padding-right: 5px!important;

        .brand {
            padding: 20px 0;
        }

        .brand-image {
            width: 100%;
            height: 200px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position-x: center;
        }
    }
}