@import '../../style/constants';

body {
    padding-top: 61px;
    transition: padding 0.2s ease;
}

.upper-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1010;
    box-shadow: 5px 3px 5px 0 $light;
}

.sidebar-container {
    top: 61px!important;
    height: calc(100% - 61px)!important;
}

.main-layout {
    width: 100%;
    z-index: 1;

    display: flex;
    flex-direction: column;

    .upper-container {
        background-color: $fontMain;
        border-bottom: 1px solid $beiger;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;

        .upper-section {
            width: 100%;
            max-width: $max-width;

            display: flex;
            justify-content: space-between;
            height: 40px;
            user-select: none;
            margin: auto;

            .xs-hidden {
                @media screen and (max-width: 600px) {
                    display: none!important;
                }
            }

            .section {
                display: flex;
                justify-content: space-between;
            }

            .left {
                .burger-menu {
                    color: #fff!important   ;

                    .icon-menu {
                        padding: 10px 10px 10px 0;
                        line-height: 40px;
                        font-size: 20px;
                        margin-right: 10px;
                        cursor: pointer;
                    }

                    @media screen and (min-width: 959px) {
                        display: none;
                    }
                }

                .left-content {
                    display: flex;
                    align-items: center;
                }

                .btn-chat {
                    position: relative;
                    padding-left: 30px;
                    height: 20px;

                    @media screen and (max-width: 500px) {
                        display: none;
                    }

                    #svg {
                        position: absolute;
                        top: 6px;
                        left: 0;
                    }

                    .text {
                        @media screen and (max-width: 500px) {
                            display: none;
                        }
                    }
                }
            }

            .right {
                .controls {
                    display: flex;
                    align-items: center;

                    .client-menu {
                        position: relative;
                        display: flex;
                        align-items: center;
                        max-width: 180px;
                        height: 40px;
                        border-radius: 5px;
                        cursor: pointer;
                        font-weight: 600;

                        &:hover, &:active, &:focus {
                            color: darken(#fff, 20);
                        }

                        @media screen and (max-width: 500px) {
                            max-width: 150px;
                            font-size: 12px;
                        }
                        
                        .parent-name {
                            padding: 5px;
                            width: 25px;
                            height: 25px;
                            min-width: 25px;
                            text-align: center;
                            background-color: $beige;
                            border-radius: 20px;

                            @media screen and (max-width: 500px) {
                                padding: 5px;
                                width: 16px;
                                height: 16px;
                                min-width: 16px;
                            }
                        }

                        .own-name {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            word-break: break-all;
                            white-space: nowrap;
                            margin-left: 3px;
                            color: $beige;
                        }

                        .client-dropdown {
                            position: absolute;
                            width: 220px;
                            top: 100%;
                            left: 0;
                            z-index: 11;
                            background-color: #fff;
                            color: $fontSecond;
                            font-size: 14px;
                            margin-top: 5px;
                            box-shadow: 0 0 5px 0 lighten($beige, 10);

                            .dropdown-item {
                                a {
                                    display: block;
                                    box-sizing: border-box;
                                    width: 100%;
                                    height: 100%;
                                    padding: 10px 15px;
                                    color: $fontSecond;
                                }

                                &.padding {
                                    padding: 10px 15px;
                                }

                                &:hover, &:active, &:focus {
                                    background-color: $beige;
                                    color: #fff;
                                }

                                .menu-text {
                                    margin-left: 10px;
                                }
                            }
                        }
                    }

                    .btn {
                        margin-left: 30px;

                        @media screen and (max-width: 767px) {
                            margin-left: 10px;
                        }

                        &:hover, &:active, &:focus {
                            .icon-user {
                                color: darken($fontMain, 30);
                                background: #cecece;
                                border-color: #cecece;
                            }
                        }

                        .icon-user {
                            color: $fontMain;
                            background: #FFFFFF;
                            border-radius: 20px;
                            border: 5px solid #FFFFFF;
                        }
                    }

                    .btn-register {
                        @media screen and (max-width: 767px) {
                            display: none;
                        }
                    }
                }

                .right-item {
                    display: flex;
                    align-items: center;
                }

                .basket {
                    display: flex;
                    align-items: center;
                    margin-left: 20px;
                    color: #fff;
                    cursor: pointer;

                    &:hover, &:active, &:focus {
                        color: darken(#fff, 20);
                    }

                    &.highlighted {
                        color: $beige;
                        border-color: $beiger;

                        .basket-count {
                            background-color: $fontMain;
                            border-color: $beige!important;
                            color: $beige!important;
                        }
                    }

                    @media screen and (max-width: 500px) {
                        font-size: 14px;
                    }

                    .text {
                        font-size: 12px;
                    }

                    .icon-cart {
                        position: relative;
                        font-size: 20px;

                        .basket-count {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            bottom: -7px;
                            right: -7px;
                            // background-color: $black    ;
                            font-size: 11px;
                            line-height: 11px;
                            font-weight: bold;
                            height: 11px;
                            width: 11px;
                            padding: 2px 4px;
                            border-radius: 10px;
                            border: 1px solid $fontMain;
                        }
                    }

                    .basket-value {
                        font-weight: bolder;
                        font-size: 24px;
                        margin-left: 10px;

                        &.highlighted {
                            font-weight: 700;
                            color: $fontSecond;
                        }

                        @media screen and (max-width: 500px) {
                            font-size: 16px;
                        }
                    }
                }

                .saved-products {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    margin-left: 20px;
                    cursor: pointer;

                    &:hover, &:active, &:focus {
                        color: darken(#fff, 20);
                    }

                    span {
                        display: table-caption;
                    }
                }
            }
        }
    }

    .lower-container {
        width: 100%;
        // background-color: $black;

        .lower-section {
            width: 100%;
            max-width: $max-width;

            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            height: 60px;
            // background-color: $black;

            .section {
                display: flex;
                justify-content: space-between;
            }

            .logo {
                .logo-text {
                    color: white;
                    letter-spacing: 2px;

                    .line-1 {
                        font-size: 38px;
                        font-weight: bolder;
                        letter-spacing: 3px;
                        color: $logoRed;
                    }

                    .line-2 {
                        font-size: 12px;
                        text-transform: uppercase;
                        color: $fontSecond;
                        padding-bottom: 5px;
                    }
                }
            }

            .search {
                .search-input {
                    position: relative;

                    @media screen and (max-width: 767px) {
                        display: flex;
                        justify-content: flex-end;
                    }

                    input {
                        width: 454px;
                        border: 1.5px solid $beiger;
                        padding-right: 35px;

                        @media screen and (max-width: 767px) {
                            width: 80%;
                        }
                    }

                    .icon-search {
                        color: $beiger;
                        position: absolute;
                        right: 5px;
                        top: 0;
                        padding: 10px;

                        &:active, &:hover, &:focus {
                            color: $grey;
                        }
                    }
                }
            }
        }
    }

    .top-categories-container {
        @media screen and (max-width: 960px) {
            display: none !important;
        }
    }

    .left-sidebar-container {
        user-select: none;
        transition: width 2s;
    }

    .right-sidebar-container {
        transition: width 2s;
    }
}

.ui.accordion .title:not(.ui), .btn, input, textarea, select, button {
  font-family: monospace!important;
}