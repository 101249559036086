@import '../../style/global.scss';

.client-orders {
    width: 100%;
    max-width: 800px;

    .ui.pagination.menu .item {
        min-width: 2em;
        text-align: center;
    }

    @media screen and (max-width: 767px) {
        padding: 0 10px;
        font-size: 12px;

        .ui.pagination.menu .item {
            min-width: 0.5em!important;
            padding: 15px!important;
        }
    }

    .client-order {
        width: 100%;
        margin: 10px 0;
        background-color: #fff;
        color: $fontSecond;
        border-radius: 5px;

        a {
            color: $fontSecond!important;
            text-decoration: underline;
        }

        .order-top {
            background-color: #ededed;
            position: relative;
            display: table;
            width: 100%;
            min-height: 50px;
            box-sizing: border-box;
            padding: 15px;
            border-radius: 4px 4px 0 0;

            .order-col {
                display: table-cell;

                .col-top {
                    font-weight: 700;
                    margin-bottom: 10px;
                }
            }

            .order-toggle {
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }

        .order-items {
            border-top: 1px solid darken($grey, 30);

            .pay-link {
                padding: 15px;
                
                a {
                    font-weight: 600;
                    color: $fontMain!important;
                }
            }

            .order-info {
                padding: 15px;
            }

            .order-address {
                padding: 15px;
            }

            .order-delivery {
                border-top: 1px solid $grey;

                .order-delivery-top {
                    padding: 15px;
                }
            }

            .order-item {
                padding: 15px;
                display: flex;
                align-items: center;
                justify-content: start;

                .item-line {
                    @media screen and (min-width: 768px) {
                        display: flex;
                        align-items: center;
                        justify-content: start;
                    }
                }
            }
        }

        .order-summary {
            border-radius: 0 0 4px 4px;
            border-top: 1px solid darken($grey, 30);
            padding: 15px;
        }
    }

    .order-status {
        font-weight: 600;
        &.placed {
            color: $fontMain;
        }
        &.paid {
            color: $green;
        }
        &.confirmed {
            color: $green;
        }
    }
}