@import '../../style/constants';

.provider-layout {
    width: 100%;
    height: 100%;
    z-index: 1;
    font-size: 12px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    flex-direction: column;
    
    @media screen and (max-width: 768px) {
        font-size: 11px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .upper-container {
        background-color: $beiger;
        border-bottom: 1px solid $beiger;
        width: 100%;

        @media print {
            display: none;
        }

        .upper-section {
            display: flex;
            justify-content: space-between;
            height: 60px;

            .section {
                display: flex;
                justify-content: space-between;
                align-items: center;

                @media screen and (max-width: 1024px) {
                    font-size: 11px!important;

                    button {
                        padding: 10px 5px!important;
                    }
                }
            }

            .left {
                padding-left: 20px;

                .left-content {
                    .logo-text {
                        text-align: center;
                        .line1 {
                            font-size: 20px;
                        }
                        .line2 {
                            font-size: 10px;
                            color: $fontSecond;
                        }
                        .line3 {
                            font-size: 10px;
                            color: $fontSecond;
                        }
                    }
                }
            }

            .right {
                padding-right: 20px;

                .controls {
                    border: 1px solid $grey;
                    border-radius: 5px;
                    padding: 10px 20px;
                    cursor: pointer;

                    &:hover, &:focus {
                        color: $fontSecond;
                        background-color: $fontMain;
                    }

                    .caret {
                        margin-left: 10px;
                    }
                }

                @media screen and (max-width: 768px) {
                    .menu .item {
                        padding: 10px 5px;
                        text-align: center;

                        button {
                            width: 77px;
                        }
                    }

                    .right.menu {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    .provider-footer {
        margin-top: 100px;
        border-top: 1px solid $beiger;
        padding: 20px;

        .contact-section {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 20px;

            .contact-left {
                padding-right: 50px;
            }
        }

        .copyright {
            border-top: 2px grey solid;
            padding: 30px 40px 40px 40px;
            text-align: center;
        }
    }
}