$black: #383838;
$grey: #ccc;
$grey2: #dadbd9;
$blue: #007bff;
$logoRed: red;
$green: #28a745;  
// new
$light: rgb(246, 243, 241);
$beige: rgb(243, 235, 231);
$beiger: rgb(221, 207, 200);
$fontMain: rgb(235, 0, 43);
$fontSecond: #1C1C1C;

$max-width: 1280px;