@import '../../style/constants.scss';

.top-product-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .product-image {
        background-size: cover;
        height: 100px;
        width: 100px;
        background-color: #fff;
    }

    .product-title {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 14px;
    }

    .price-line {
        .price {
            font-size: 18px;
            color: $fontSecond;
        }

        .price-per {
            font-size: 10px;
        }
    }
}