@import '../../style/constants.scss';
@import "react-alice-carousel/lib/scss/alice-carousel.scss";

.top-products-container {
    border-radius: 5px;
    width: 100%;

    .title {
        padding: 20px;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .top-products-content {
        position: relative;
        padding: 0 40px 20px 40px;

        .arrow-control {
            position: absolute;
            top: 0;
            bottom: 20px;
            height: calc(100% - 20px);
            width: 30px;

            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            z-index: 10;

            &.left {
                left: 0;
            }

            &.right {
                right: 0;
            }

            &:hover, &:active, &:focus {
                background: lighten($fontSecond, 10);
                color: $grey;
                outline: none!important;
            }

            i {
                margin: 0;
            }
        }
    }
}