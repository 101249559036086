@import "../../style/constants.scss";

.products-filter-container {
    .filter {
        border-top: 1px solid $beiger;

        .filter-title {
            font-weight: 600;
            padding: 5px 0 10px 10px;
            border-bottom: 1px solid $beiger;
        }
    
        .filter-body {
            padding: 10px;
        }

        .input-range__label {
            font-family: proxima_nova, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        }

        .input-range__slider, .input-range__track--active {
            background: $fontMain;
            border-color: $fontMain;
        }

        .checkbox {
            cursor: pointer;
            height: 35px;
            line-height: 35px;

            label {
                margin-left: 8px;
            }
        }
    }

    .sale-filter {
        height: 50px;
    }

    .price-filter {
        height: 100px;

        .filter-body {
            padding: 25px 35px;
        }
    }

    .size-filter {
        height: 160px;

        .filter-line {
            height: 50px;
        }

        .filter-body {
            padding: 25px;
        }
    }
}