@import '../../style/constants.scss';

.checkout-page {
    margin-top: 50px;

    @media only screen and (max-width: 1023px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .checkout-container {
        .check-out-line {
            display: flex;
            justify-content: center;
            text-transform: uppercase;
            margin-top: 40px;

            button {
                height: 50px;
                width: 320px;
                margin: 0;
            }
        }

        .address-section {
            display: flex;
            justify-content: center;

            .address-section-inner {
                text-align: center;
            }

            .address-component-container {
                width: 320px;
            }

            .selection.dropdown {
                min-width: 320px;
                min-height: 43px!important;
                box-sizing: border-box;
            }
        }

        .error-msg {
            text-align: center;
            margin: 20px 0;
        }

        .checkout-content {
            @media screen and (max-width: 767px) {
                width: 100%;
            }

            .delivery-list {
                margin-top: 30px;
            }

            .products-table {
                margin: 20px 5px 20px 5px;
                border-radius: 5px;
                padding: 10px;
                box-shadow: 0 0 10px 0 lighten($beige, 10);
                color: $fontSecond;

                &:hover, &:active {
                    box-shadow: 0 0 10px 0 lighten($beige, 10);
                }

                .warning-msg, .error-msg {
                    padding: 10px 0;
                    text-align: left!important;
                    margin: 0!important;
                }

                .products-row {
                    flex: 1 1 auto;
                    width: 100%;
                    display: flex;
                    flex-direction: row;

                    .ritem {
                        flex: 2;
                        padding: 10px 5px;
                        margin: auto;

                        &.product-image, &.date-icon {
                            flex: unset!important;
                            width: 40px;
                        }

                        &.date-picker {
                            flex: 6!important;
                            
                            .selection.dropdown {
                                margin-left: 10px;
                                width: 160px!important;
                                min-width: 150px!important;
                                min-width: unset!important;
                                height: 43px!important;
                                box-sizing: border-box;

                                .option {
                                    width: 160px!important;
                                    min-width: unset!important;
                                }
                            }

                            .delivery-time {
                                display: inline;    
                                margin-left: 15px;

                                @media screen and (max-width: 400px) {
                                    display: block;
                                    margin-top: 10px;
                                }
                            }
                        }

                        &.flex-14 {
                            flex: 14;
                        }
                    }

                    .product-row-right {
                        @media screen and (max-width: 767px) {
                            display: block!important;
                            width: 100%;
                            font-size: 12px;

                            input {
                                height: 44px!important;
                            }

                            .product-counts, .product-price {
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                            }
                        }

                        .product-price {
                            input {
                                background-color: #fff;
                                padding-left: 10px;
                                height: 48px;
                                width: 120px;
                                font-weight: 600;
                                font-size: 14px;
                                text-align: center;
                                line-height: 12px;
                                border-top: 1px solid $fontSecond;
                                border-bottom: 1px solid $fontSecond;
                                border-left: none;
                                border-right: none;
                                border-radius: 0;
                                box-sizing: border-box;
        
                                &.count-error {
                                    border-color: $fontMain;
                                }
                            }

                            .btn-price-change {
                                padding: 10px;
                                margin-left: 10px;
                            }
                        }
                    }

                    .date-container {
                        position: relative;

                        .ui.small.loader {
                            position: absolute;
                            top: 8px;
                            left: 80px;
                        }
                    }
                }

                .delivery-item {
                    border-bottom: 1px solid $grey;
                }

                .basket-item {
                    border-bottom: 1px solid $grey;

                    .product-details {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex: 6;
    
                        @media screen and (max-width: 767px) {
                            display: flex!important;
                        }
    
                        .product-name {
                            margin-left: 5px;
    
                            &.count-error {
                                color: $fontMain;
                            }
    
                            .name {
                                font-weight: bold;
                                cursor: pointer;
                            }
                        }
                    }
    
                    .product-counts {
                    
                        .product-count {
                            display: flex!important;
                            align-items: center;
                            justify-content: flex-start;
                            
                            input {
                                background-color: #fff;
                                padding-left: 10px;
                                height: 48px;
                                width: 60px;
                                font-weight: 600;
                                font-size: 14px;
                                text-align: center;
                                line-height: 12px;
                                border-top: 1px solid $fontSecond;
                                border-bottom: 1px solid $fontSecond;
                                border-left: none;
                                border-right: none;
                                border-radius: 0;
                                box-sizing: border-box;
        
                                &.count-error {
                                    border-color: $fontMain;
                                }
                            }
        
                            i.caret {
                                background-color: #fff;
                                border: 1px solid $fontSecond;
                                padding: 15px;
                                margin: 0;
                                cursor: pointer;
    
                                &::before {
                                    color: $fontSecond;
                                }
        
                                &:hover, &:active {
                                    background-color: $fontSecond;
                                    color: #fff;
    
                                    &::before {
                                        color: $fontMain;
                                    }
                                }
        
                                &.down {
                                    border-top-left-radius: 5px;
                                    border-bottom-left-radius: 5px;
                                }
        
                                &.up {
                                    border-top-right-radius: 5px;
                                    border-bottom-right-radius: 5px;
                                }
                                
                                &.count-error {
                                    border-color: $fontMain;
                                }
                            }
                        }
                    }
    
                    .stock-line {
                        color: $fontMain;
                        font-size: 12px;
                    }
    
                    .price-per {
                        margin-top: 10px;
    
                        .remove {
                            cursor: pointer;
                            margin-left: 10px;
    
                            &:hover, &:active {
                                color: $fontMain;
                            }
                        }
                    }
                }
            }

            .total-amount {
                text-align: center;
                font-size: 24px;
                font-weight: 700;
                color: $fontSecond;
                padding-top: 20px;
                margin-top: 20px;
                padding-bottom: 20px;
            }

            .delivery-info {
                text-align: center;
                margin: 30px 0;
                font-weight: 700;
            }
        }

        .pay-later-line {
            text-align: center;
            margin-top: 30px;

            input {
                height: 16px;
                width: 16px;
            }

            .pay-line {
                margin-top: 20px;
            }
        }
    }
}