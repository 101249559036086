@import '../../../style/constants';

.admin-layout {
    width: 100%;
    height: 100%;
    min-height: 500px!important;
    z-index: 1;
    background-color: #fff;
    font-size: 12px;
    color: $fontSecond;

    display: flex;
    justify-content: center;
    flex-direction: column;
    
    @media screen and (max-width: 768px) {
        font-size: 11px;
    }

    .upper-container {
        background-color: $black;
        border-bottom: 1px solid $beiger;
        width: 100%;

        @media print {
            display: none;
        }

        .upper-section {
            display: flex;
            justify-content: space-between;
            height: 60px;

            .section {
                display: flex;
                justify-content: space-between;
                align-items: center;

                @media screen and (max-width: 1024px) {
                    font-size: 11px!important;

                    button {
                        padding: 10px 5px!important;
                    }
                }
            }

            .left {
                padding-left: 20px;
                .burger-menu {
                    color: #fff;

                    .icon-menu {
                        padding: 15px;
                        line-height: 50px;
                        font-size: 30px;
                        cursor: pointer;
                    }
                }

                .left-content {
                    display: flex;
                    align-items: center;
                }
            }

            .right {
                padding-right: 20px;
                .controls {
                    border: 1px solid $grey;
                    border-radius: 5px;
                    padding: 10px 20px;
                    cursor: pointer;

                    &:hover, &:focus {
                        color: $fontSecond;
                        background-color: $fontMain;
                    }

                    .caret {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .left.sidebar {
        width: 200px!important;
    }

    .admin-layout-content {
        padding: 20px;
        min-height: 800px!important;
        display: flex;
        justify-content: center;

        a {
            color: $fontSecond
        }

        input, textarea {
            box-sizing: border-box;
        }

        .error-msg {
            color: $fontMain;
            padding: 10px;
        }

        .success-msg {
            color: green;
            padding: 10px;
        }

        .flex-space-between {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .flex-start {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .underline {
            text-decoration: underline;
        }

        .cursor {
            cursor: pointer;
        }

        .chef-icon-hover {
            &:hover {
                color: $fontMain;
            }
        }

        .hover-blue-background {
            &:hover {
                background-color: $blue;
                color: $fontMain;
            }
        }

        .padding-5 {
            padding: 5px;
        }

        .margin-10 {
            margin: 10px;
        }

        .hidden-div {
            display: none!important;
        }

        .full-width {
            width: 100%;
        }

        .selection.dropdown {
            min-height: 43px!important;
            height: 43px!important;
            box-sizing: border-box;
        }
    }
}