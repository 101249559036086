@import '../../style/constants.scss';

.top-categories-container {
    
    // background-color: $black;
    
    .top-categories {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1 1 auto;
        height: 45px;
        border-radius: 5px;
        z-index: 10;

        .top-sub-categories {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            background: #fff;
            margin-top: 20px;
            border-radius: 5px;
            border: 1px solid $grey;
            z-index: 10;
            
            .top-sub-categories-inner {
                padding-bottom: 25px;
            }
    
            .category-title {
                padding-left: 20px;
                padding-right: 20px;
                border-top: 1px solid darken($beiger, 10);
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;
    
                .shop-now {
                    margin-left: 20px;
                }
            }
    
            .sub-categories-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding-top: 20px;
                padding-left: 20px;
                padding-right: 20px;
    
                .sub-category-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 0!important;

                    .sub-link {
                        display: contents;
                    }
    
                    .sub-image {
                        height: 150px;
                        width: 80%;
                        max-width: 150px;
                        background-size: cover;
                    }
    
                    .shop-now {
                        max-width: 140px;
                        margin-top: 10px;
                        font-size: 12px;
                        font-weight: bold;
                    }
                }
    
                .more-sub-categories {
                    width: 100%;
    
                    .more-title {
                        width: 100%;
                        border-bottom: 1px solid #ccc;
                    }
                    
                    .more-sub-categories-list {
                        display:flex;
                        justify-content: flex-start;
                        margin-top: 20px;
    
                        a {
                            margin-right: 30px;
                        }
                    }
                }
            }
        }

        .top-category-item {
            flex: 1 1 6%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            height: 65px;
            font-size: 13px;
            line-height: 16px;
            font-weight: 700;
            cursor: pointer;
            -webkit-font-smoothing: antialiased;
            background-color: $beiger;

            // background-image: linear-gradient(rgb(31, 99, 0), rgb(19, 59, 0));
            background-image: linear-gradient(lighten($beige, 4), darken($beiger, 4));
            // box-shadow: rgba(1, 3, 0, 0.25) 0px 2px 2px, rgb(44, 140, 0) 0px 1px 0px inset, rgba(44, 140, 0, 0.4) 1px 0px 0px inset;
            box-shadow: $beige 0px 2px 2px, $beige 0px 1px 0px inset, $beiger 1px 0px 0px inset;
            text-shadow: $light 0px 1px 2px;
            background-repeat: repeat-x;
            border-width: 1px 1px 1px;
            border-style: solid none solid solid;
            border-color: $beiger;
            border-image: initial;
            border-right: none;

            @media screen and (max-width: 1080px) {
                font-size: 12px;
                font-weight: 500;
            }

            &:last-child {
                border-radius: 0 5px 5px 0;
            }

            &:first-child {
                border-radius: 5px 0 0 5px;
            }

            &:hover, &:active, &:focus {
                background-image: linear-gradient(#fff, $light);
                a {
                    color: darken($fontMain, 40);
                }
            }

            .category-name {
                padding: 0 5px;
            }

            a {
                text-align: center;
                color: $fontMain;
            }
        }
    }
}