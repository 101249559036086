@import "../../style/constants.scss";

.products-container {
    position: relative;
    display: flex;
    border-radius: 5px;
    margin-top: 20px;

    // &.bordered {
    //     @media screen and (min-width: 768px) {
    //         border: 1px solid $beiger;
    //     }
    // }

    @media screen and (max-width: 767px) {
        border: none;
    }

    .products-content {
        width: calc(100% - 251px);

        @media screen and (max-width: 767px) {
            width: 100%;
        }
        
        .products-title {
            padding: 10px;
            line-height: 20px;
            font-weight: 700;
            border-bottom: 1px solid $beiger;
            display: flex;

            @media screen and (max-width: 767px) {
                border: none;
            }

            .sort-button {
                cursor: pointer;
            }

            .filter-button {
                margin-left: 20px;
                cursor: pointer;

                @media screen and (min-width: 768px) {
                    display: none;
                }
            }
        }

        .products-body {
            display: flex;
            flex-wrap: wrap;

            .no-product {
                padding: 20px;
            }
        }

        .product-item-col {
            display: flex;
            justify-content: center;
            padding-left: 5px!important;
            padding-right: 5px!important;
        }
    }

    .products-filter {
        width: 250px;
        border-right: 1px solid $beiger;
        background-color: $beige;
        border-radius: 10px;
        padding: 5px;

        @media screen and (max-width: 767px) {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
            transition: all 0.5s;
            max-height: 0;
            display: none;
            border: 1px solid $beiger;
            border-radius: 5px;

            &.filter-open {
                width: 100%;
                max-height: unset;
                display: block;
                padding-left: 15px;
                padding-right: 15px;
                box-sizing: border-box;
            }
        }

        .filter-section-title {
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            padding: 10px;
            line-height: 20px;

            .filter-close {
                @media screen and (min-width: 768px) {
                    display: none;
                }
            }

            span {
                cursor: pointer;
            }
        }
    }
}