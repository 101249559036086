@import '../../style/constants.scss';

.footer {
    width: 100%;
    margin-top: 40px;
    font-size: 12px;
    box-shadow: 0px -5px 5px $light;

    .footer-content {
        padding: 40px;
        line-height: 20px;

        .footer-column {
            padding: 0 10px;

            .item {
                padding: 10px 0;
                
                a {
                    &:hover, &:active, &:focus {
                        color: $grey;
                    }
                }
            }
        }
    }

    .copyright {
        border-top: 2px $light solid;
        padding: 30px 40px 40px 40px;
        text-align: center;
    }
}