@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?fcfqgm');
  src:  url('fonts/icomoon.eot?fcfqgm#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?fcfqgm') format('truetype'),
    url('fonts/icomoon.woff?fcfqgm') format('woff'),
    url('fonts/icomoon.svg?fcfqgm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: "\e900";
}
.icon-home2:before {
  content: "\e901";
}
.icon-home3:before {
  content: "\e902";
}
.icon-office:before {
  content: "\e903";
}
.icon-pencil:before {
  content: "\e905";
}
.icon-image:before {
  content: "\e90d";
}
.icon-images:before {
  content: "\e90e";
}
.icon-camera:before {
  content: "\e90f";
}
.icon-film:before {
  content: "\e913";
}
.icon-book:before {
  content: "\e91f";
}
.icon-library:before {
  content: "\e921";
}
.icon-file-text2:before {
  content: "\e926";
}
.icon-file-music:before {
  content: "\e928";
}
.icon-file-play:before {
  content: "\e929";
}
.icon-stack:before {
  content: "\e92e";
}
.icon-folder-plus:before {
  content: "\e931";
}
.icon-folder-download:before {
  content: "\e933";
}
.icon-folder-upload:before {
  content: "\e934";
}
.icon-qrcode:before {
  content: "\e938";
}
.icon-cart:before {
  content: "\e93a";
}
.icon-coin-dollar:before {
  content: "\e93b";
}
.icon-credit-card:before {
  content: "\e93f";
}
.icon-phone:before {
  content: "\e942";
}
.icon-address-book:before {
  content: "\e944";
}
.icon-envelop:before {
  content: "\e945";
}
.icon-pushpin:before {
  content: "\e946";
}
.icon-location:before {
  content: "\e947";
}
.icon-location2:before {
  content: "\e948";
}
.icon-compass:before {
  content: "\e949";
}
.icon-clock2:before {
  content: "\e94f";
}
.icon-alarm:before {
  content: "\e950";
}
.icon-calendar:before {
  content: "\e953";
}
.icon-printer:before {
  content: "\e954";
}
.icon-display:before {
  content: "\e956";
}
.icon-mobile:before {
  content: "\e958";
}
.icon-tablet:before {
  content: "\e95a";
}
.icon-bubbles:before {
  content: "\e96c";
}
.icon-bubbles3:before {
  content: "\e96f";
}
.icon-user:before {
  content: "\e971";
}
.icon-users:before {
  content: "\e972";
}
.icon-user-plus:before {
  content: "\e973";
}
.icon-user-minus:before {
  content: "\e974";
}
.icon-spinner2:before {
  content: "\e97b";
}
.icon-spinner5:before {
  content: "\e97e";
}
.icon-spinner8:before {
  content: "\e981";
}
.icon-search:before {
  content: "\e986";
}
.icon-zoom-in:before {
  content: "\e987";
}
.icon-zoom-out:before {
  content: "\e988";
}
.icon-enlarge:before {
  content: "\e989";
}
.icon-shrink:before {
  content: "\e98a";
}
.icon-enlarge2:before {
  content: "\e98b";
}
.icon-key:before {
  content: "\e98d";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-unlocked:before {
  content: "\e990";
}
.icon-cogs:before {
  content: "\e995";
}
.icon-magic-wand:before {
  content: "\e997";
}
.icon-stats-dots:before {
  content: "\e99b";
}
.icon-stats-bars:before {
  content: "\e99c";
}
.icon-stats-bars2:before {
  content: "\e99d";
}
.icon-trophy:before {
  content: "\e99e";
}
.icon-gift:before {
  content: "\e99f";
}
.icon-glass:before {
  content: "\e9a0";
}
.icon-glass2:before {
  content: "\e9a1";
}
.icon-mug:before {
  content: "\e9a2";
}
.icon-spoon-knife:before {
  content: "\e9a3";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-menu:before {
  content: "\e9bd";
}
.icon-menu2:before {
  content: "\e9be";
}
.icon-menu3:before {
  content: "\e9bf";
}
.icon-menu4:before {
  content: "\e9c0";
}
.icon-cloud:before {
  content: "\e9c1";
}
.icon-upload2:before {
  content: "\e9c6";
}
.icon-download3:before {
  content: "\e9c7";
}
.icon-upload3:before {
  content: "\e9c8";
}
.icon-attachment:before {
  content: "\e9cd";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-eye-blocked:before {
  content: "\e9d1";
}
.icon-bookmark:before {
  content: "\e9d2";
}
.icon-bookmarks:before {
  content: "\e9d3";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-half:before {
  content: "\e9d8";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-heart:before {
  content: "\e9da";
}
.icon-happy:before {
  content: "\e9df";
}
.icon-smile:before {
  content: "\e9e1";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-arrow-right:before {
  content: "\ea34";
}
.icon-arrow-down-right:before {
  content: "\ea35";
}
.icon-arrow-down:before {
  content: "\ea36";
}
.icon-arrow-down-left:before {
  content: "\ea37";
}
.icon-arrow-left:before {
  content: "\ea38";
}
.icon-arrow-up-left2:before {
  content: "\ea39";
}
.icon-arrow-up2:before {
  content: "\ea3a";
}
.icon-arrow-up-right2:before {
  content: "\ea3b";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-down-right2:before {
  content: "\ea3d";
}
.icon-arrow-down2:before {
  content: "\ea3e";
}
.icon-arrow-down-left2:before {
  content: "\ea3f";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-crop:before {
  content: "\ea57";
}
.icon-clear-formatting:before {
  content: "\ea6f";
}
.icon-google:before {
  content: "\ea88";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-chrome:before {
  content: "\ead9";
}
.icon-firefox:before {
  content: "\eada";
}
.icon-IE:before {
  content: "\eadb";
}
.icon-edge:before {
  content: "\eadc";
}
.icon-safari:before {
  content: "\eadd";
}
.icon-opera:before {
  content: "\eade";
}
