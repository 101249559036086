@import '../../style/constants.scss';

.brand-page {
    @media screen and (min-width: 961px) {
        margin-top: 50px;
    }

    @media only screen and (max-width: 1023px) {
        padding-left: 15px!important;
        padding-right: 15px!important;
    }

    .brand-page-container {

        @media screen and (min-width: 961px) {
            padding-top: 20px;
        }

        .brand-header {
            width: 100%;
        }

        .breadcrumb {
            margin-bottom: 20px;
        }

        .brand-image {
            width: 100%;

            img.image {
                width: 100%;
                max-height: 200px;

                @media screen and (min-width: 1024px) {
                    max-height: 320px;
                }
            }
        }
    }
}