@import '../../style/global.scss';
@import '../../style/constants.scss';

.warehouse-form {
    width: 100%;
    margin-top: 20px;
    color: $fontSecond!important;

    h2 {
        color: $fontSecond;
    }

    .form-group {
        width: 420px;
        margin: 20px auto auto auto;
        color: $fontSecond!important;

        @media screen and (max-width: 500px) {
            width: 100%!important;
        }

        .label {
            color: $fontSecond;
            font-weight: 700;
            margin-bottom: 10px;
        }
    }

    .info-group {
        margin-top: 20px;
        
        .label {
            color: $fontSecond;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .value {
            color: #fff;
            padding: 10px;
            font-weight: 700;
            font-size: 20px;
        }
    }

    .warehouse-actions {
        max-width: 800px;
        margin: auto;

        .report-filter {
            display: flex;
            justify-content: space-between;

            margin-top: 30px;
            margin-bottom: 30px;

            @media screen and (max-width: 600px) {
                width: 100%;
                flex-direction: column;

                .field {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }

        @media screen and (max-width: 767px) {
            .ui.table {
                thead {
                    display: table-header-group!important;
                }
                tbody {
                    display: table-row-group!important;
                }
                tr {
                    display: table-row!important;                    
                }
                td, th {
                    display: table-cell!important;
                    word-break: break-word;
                    padding: 3px!important;
                    font-size: 11px!important;
                }
            }
        }

        .plus {
            color: $fontMain;
        }

        .minus {
            color: $fontMain;

            &.confirm {
                color: $green!important;
                font-weight: 700;
                font-size: 14px;
            }
        }

        .updated {
            color: $fontMain;
            font-weight: 600;
        }
    }
}