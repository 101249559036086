@import '../../style/constants.scss';

.product-page {
    @media screen and (min-width: 961px) {
        margin-top: 50px;
    }

    @media only screen and (max-width: 799px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .breadcrumb {
        padding: 10px 0;
        margin-bottom: 10px;
        border-bottom: $grey solid 1px;
    }

    .brand-line {
        background-size: contain;
        min-height: 150px;
        padding: 15px 0;
        margin-top: 20px;
        text-align: center;

        .brand-image {
            width: 100%;
            height: auto;
        }
    }

    .product-price-line {
        @media screen and (max-width: 799px) {
            margin-top: 30px;
        }

        .price {
            font-size: 30px;
            font-weight: 600;

            &.striked {
                font-size: 20px;
                color: $fontMain;
                text-decoration: line-through;
            }
        }

        .price-per {
            font-size: 18px;
            color: $grey;
        }
    }

    .save-line {
        text-align: center;
        margin-top: 30px;
        width: 100%;

        button {
            position: relative;
            width: 100%;
            height: 40px;

            .loader {
                margin: auto;
                padding-top: 3px!important;
                top: 18px;
            }
        }
    }

    .delivery-line {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1023px) {
            justify-content: flex-start;
            flex-direction: column;
        }

        border-top: 1px solid $beige;
        border-bottom: 1px solid $beige;
        padding: 10px 0;
        text-align: center;
        margin-top: 30px;
        color: $fontSecond;

        .text {
            font-size: 18px;
        }

        .icon {
            border: 1px solid $fontSecond;
            border-radius: 30px;
            padding: 10px;
        }
    }

    .warehouse-line {
        font-size: 14px;
        line-height: 14px;
        padding: 10px 0;
    }

    .basket-count {
        font-size: 12px;
        line-height: 14px;
        padding: 10px 0;
    }

    .basket-msg {
        color: $fontMain;
        font-size: 12px;
        line-height: 14px;
        padding: 10px 0;
    }

    .basket-line {
        display: flex;
        align-items: center;
        padding: 10px 0;

        .btn-basket {
            width: 100%;
            height: 40px;
            border-radius: 0 5px 5px 0;
            box-sizing: border-box;
            background-color: $beige;
        }

        input {
            width: 75px;
            height: 40px;
            border-radius: 5px 0 0 5px;
            border: none;
            font-size: 18px;
            text-align: center;
            box-sizing: border-box;

            &:hover, &:focus, &:active {
                outline: none;
                border: none;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }
    }

    .main-product-image {
        max-width: 100%;
        height: auto;
        align-self: flex-start;
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
    }

    .mobile-container {
        padding-top: 20px;

        @media screen and (min-width: 800px) {
            display: none;
        }

        .breadcrumb {
            font-size: 12px;
        }

        h2 {
            font-size: 20px;
            font-weight: 600;
            line-height: 35px;
        }

        .price-line {
            text-align: center;
            margin-top: 30px;
            .price {
                font-size: 20px;
            }
            .price-per {
                font-size: 16px;
            }
        }

        .basket-line {
            margin-top: 10px;
        }

        .brand-line {
            min-height: 100px;
    
            .brand-image {
                width: auto;
                height: 100px;
                margin-bottom: 10px;
            }
        }

        .product-detail-images {
            margin-top: 30px;
            width: 100%;
            padding: 10px 0;

            .product-detail-image {
                display: flex;
                justify-content: center;
                margin-top: 20px;

                .detail-image {
                    height: auto;
                    max-width: 100%;
                    align-self: flex-start;
                    width: auto;
                }
            }
        }

        .top-products-container {
            margin-top: 30px;
            text-align: center;
        }
    }

    .product-page-container {
        @media screen and (max-width: 799px) {
            display: none;
        }
        
        h2 {
            font-size: 30px;
            font-weight: 600;
            line-height: 35px;
        }

        .product-left {

            .top-products-section {
                border: 1px solid $beige;
                margin-top: 20px;
            }

            .top-products-container {
                .title {
                    font-size: 20px;
                }
            }

            .product-detail-images {
                margin-top: 30px;
                width: 100%;

                .product-detail-image {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }

                .detail-image {
                    height: auto;
                    max-width: 100%;
                    width: auto;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position-x: center;
                }
            }
        }

        .product-right {
            .right-section {
                padding: 15px;
                background-color: $fontMain;
                color: #fff;
                border-radius: 10px;
            }
        }
    }
}