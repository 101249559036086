html {
  margin: 0;
}

body {
  margin: 0;
  /* font-family: "LXGW WenKai Mono TC", monospace; */
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(235, 0, 43);
  background: #ffffff;
  letter-spacing: 0.4px;
}

body.no-scroll {
  overflow: hidden!important;
}

div {
  box-sizing: border-box;
}

.page {
  max-width: 1280px;
  margin: auto;
}

@media only screen and (min-width: 1024px) {
  .page {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.modals {
  max-width: 100%!important;
  padding: 0!important;
  margin: 0!important;
}
